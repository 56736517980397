import "../../../styles.css";
import React, { useState, useContext } from "react";
import { AccountContext } from "../../login/components/Account";
import {
  TopTraderSummaryTable,
  TopTraderPositionTable,
  TopTraderRecentTradesSmall,
  TopTraderRecentTradesMedium,
  TopTraderRecentTradesLarge,
  TopTraderBiggest,
} from "./TopTradersContent";
import { LongShortRatioPositionBenchmark } from "./TopTradersRatioPositionContent";
import { LongShortRatioAccountBenchmark } from "./TopTradersRatioAccountContent";
import {
  PageHeader,
  GraphTitle,
  GraphSubTitle,
  GridContainer,
  GridUniversalStyle,
  Card,
  ButtonContainer,
  NoticeButtonContainer,
  ContentNotAvailable,
  NoticeTitle,
  NoticeSubTitle,
  LoginButton,
  LoginButtonAlt,
  StyledLink,
  SmallSubHeader,
  TextContainer,
  ButtonBox,
  ButtonBoxRight,
} from "../../constants/components/Universal.styles";
import Grid from "@material-ui/core/Grid";

export default function TopTraderPage() {
  const { role } = useContext(AccountContext);
  const [isTextVisible, setIsTextVisible] = useState(false); // State to control the visibility of the text

  const toggleTextVisibility = () => {
    setIsTextVisible((prevState) => !prevState);
  };

  // if (role === "notloggedin") {
  //   return (
  //     <>
  //       <ButtonContainer></ButtonContainer>
  //       <ContentNotAvailable>
  //         <NoticeTitle>Content Not Available</NoticeTitle>
  //         <NoticeSubTitle>You must be signed in to view</NoticeSubTitle>
  //         <NoticeButtonContainer>
  //           <StyledLink to="/login">
  //             <LoginButton>Login</LoginButton>
  //           </StyledLink>
  //           <StyledLink to="/register">
  //             <LoginButtonAlt>Register</LoginButtonAlt>
  //           </StyledLink>
  //         </NoticeButtonContainer>
  //       </ContentNotAvailable>
  //     </>
  //   );
  // }

  return (
    <>
      <ButtonContainer>
        <ButtonBoxRight onClick={toggleTextVisibility}>
          What is this page?
        </ButtonBoxRight>
      </ButtonContainer>

      {isTextVisible && (
        <Grid container wrap="nowrap" style={GridContainer}>
          <Grid item xs={12}>
            <GridUniversalStyle>
              <TextContainer>
                The page you are viewing presents data on the top 100 traders on
                Binance who have chosen to publicly share their trading
                positions.
                <br />
                <br />
                The data is filtered to display trades above certain value
                thresholds: $100,000, $1,000,000, and $10,000,000.
                <br />
                <br />
                In addition to trade details, the page also shows several key
                performance indicators for each trader, including their monthly
                profit and loss (PnL), monthly return on investment (ROI), and
                total ROI. These metrics provide a quantifiable measure of the
                trader's performance and efficiency, which can be beneficial
                when comparing different traders or tracking the success of
                specific strategies.
                <br />
                <br />
                One of the unique features of this platform is its integration
                with Telegram. By adding your Telegram handle in the 'Account'
                settings, you can receive alerts directly to your Telegram
                account whenever trades valued over $100,000 are opened or
                closed. This feature allows for real-time updates, meaning you
                can stay up-to-date with the latest significant moves made by
                the top traders. It's a useful tool for those looking to keep a
                close eye on market activity, anticipate possible trends, or
                simply stay informed about what top traders are doing.
              </TextContainer>
            </GridUniversalStyle>
          </Grid>
        </Grid>
      )}

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>Top Traders - All Positions</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderPositionTable />
            </Card>
          </GridUniversalStyle>
        </Grid>

      </Grid>


      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Most Recent Trades - Over $100k</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderRecentTradesSmall />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Most Recent Trades - Over $1m</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderRecentTradesMedium />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Most Recent Trades - Over $10m</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderRecentTradesLarge />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
      <Grid item xs={12}>
          <GridUniversalStyle>
            <GraphTitle>Top Trader Coin Summary</GraphTitle>
            <GraphSubTitle>Top 100 Binance Traders</GraphSubTitle>
            <Card>
              <TopTraderSummaryTable />
            </Card>
          </GridUniversalStyle>
        </Grid>
        </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={6}>
          <GridUniversalStyle hasBorderRight>
            <GraphTitle>Long/Short Ratio - Top ACCOUNTS</GraphTitle>
            <GraphSubTitle>Benchmark</GraphSubTitle>
            <Card>
              <LongShortRatioAccountBenchmark />
            </Card>
          </GridUniversalStyle>
        </Grid>
        <Grid item xs={6}>
          <GridUniversalStyle>
            <GraphTitle>Long/Short Ratio - POSITIONS</GraphTitle>
            <GraphSubTitle>Benchmark</GraphSubTitle>
            <Card>
              <LongShortRatioPositionBenchmark />
            </Card>
          </GridUniversalStyle>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" style={GridContainer}>
        <Grid item xs={12}>
          <GridUniversalStyle>
            <TextContainer>
              <a
                href="https://forms.gle/4Njv5TSAN4EPGYvx7"
                target="_blank"
                rel="noAvaxener noreferrer"
              >
                <SmallSubHeader>
                  Click here to help us with feedback or suggestions
                </SmallSubHeader>
              </a>
            </TextContainer>
          </GridUniversalStyle>
        </Grid>
      </Grid>
    </>
  );
}
